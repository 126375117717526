<template>
  <v-container class="plugin-wall d-flex flex-column justify-start">
    <v-row class="align-center mb-n16">
      <v-col class="d-flex" lg="2">
        <v-btn class="ma-1" v-if="move" @click="moveAction" color="success">{{
          $t("move here")
        }}</v-btn>
        <v-btn
          class="ma-1"
          @click="cancelMoveFolder"
          v-if="move"
          color="error"
          >{{ $t("cancel") }}</v-btn
        >
      </v-col>
      <v-col class="d-flex justify-end" lg="10">
        <v-icon
          @click="back"
          style="cursor: pointer;"
          v-if="parent"
          class="ma-1"
          x-large
          >mdi-arrow-left</v-icon
        >
      </v-col>
    </v-row>
    <!-- <v-breadcrumbs :items="breadcrumbs" large></v-breadcrumbs> -->

    <plugin-form
      @moveFolder="setMoveFolder($event)"
      @moveFile="setMoveFile($event)"
      @setParent="parent = $event.id"
      :page="page"
      :fromBase="true"
      :parent="this.parent"
      :lg="2"
      :md="3"
      :sm="6"
      :cols="12"
    />
    <pagination
      v-if="!parent & !loader"
      @updatePage="updatePage($event)"
      :data="meta"
    />
  </v-container>
</template>
<script>
import pluginForm from "@/components/mycomponent/base/plugin/index.vue";
import { Folder } from "@/store/file-system/index";
import { File } from "../../../store/file-system/file";

export default {
  components: {
    pluginForm,
  },
  data() {
    return {
      page: 1,
      plugin: false,
      parent: false,
      move: false,
      moveFile: null,
      typeFile: null,
      Folder,
      File,
    };
  },

  methods: {
    updatePage(event) {
      Folder.get({ page: event, parent_id: "null" });
    },

    closePopup() {
      this.$emit("closePopup");
      this.plugin = false;
      this.moveFile = null;
      this.move = false;
    },
    setFile(file) {
      this.$emit("fileChoosen", file);
    },
    async back() {
      if (Folder.data.parent) {
        await Folder.show(Folder.data.parent.id, {
          onSuccess: () => {
            Folder.itemsBreadcrumbs.pop();
          },
        });
      } else {
        await Folder.get({ page: 1, parent_id: "null" });
        this.parent = null;
      }
      console.log(Folder.itemsBreadcrumbs);
    },
    setMoveFolder(data) {
      this.typeFile = "folder";
      Folder.get({ page: 1, parent_id: "null" });
      this.move = true;
      this.moveFile = data;
    },
    setMoveFile(data) {
      this.typeFile = "file";
      this.move = true;
      this.moveFile = data;
    },
    moveAction() {
      if (this.typeFile == "folder") {
        this.moveFolder();
      } else if (this.typeFile == "file") {
        this.fileMove();
      }
    },
    moveFolder() {
      if ("parent" in Folder.data) {
        Folder.put(this.moveFile.id, { parent_id: Folder.data.id });
      } else {
        Folder.put(this.moveFile.id, { parent_id: null });
      }

      this.moveFile = null;
      this.move = false;
      this.typeFile = null;
    },
    fileMove() {
      File.put(this.moveFile.id, { folder_id: Folder.data.id });

      this.moveFile = null;
      this.move = false;
    },
    cancelMoveFolder() {
      this.moveFile = null;
      this.move = false;
    },
  },
  computed: {
    dialogStatus() {
      return this.status;
    },
    getWidth() {
      if (this.plugin) {
        return "900px";
      } else {
        return "600px";
      }
    },
    meta() {
      return Folder.data.meta;
    },

    loader() {
      return Folder.getState.loading;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/md/variables";
.title-row {
  .title {
    color: $myBlue;
  }
  .btn {
    color: white !important;
    background-color: $myBlue !important;
    span {
      font-size: 0.5vw;
    }
  }
}
.base {
  width: 40%;
  height: 100%;
  border-radius: 10px !important;
}
.plugin-wall {
  background-color: white;
  height: 90vh;
}
h2 {
  font-weight: 900;
}
</style>
